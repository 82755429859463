import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import type { MessagePagesAndParams } from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import type {
  Article,
  BlogTopicMessage,
  TopicMessage
} from '@aurora/shared-generated/types/graphql-schema-types';
import { ConversationStyle } from '@aurora/shared-generated/types/graphql-schema-types';
import type { EndUserComponent } from '@aurora/shared-types/pages/enums';
import { EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import {
  stripHtmlFromString,
  truncateString
} from '@aurora/shared-utils/helpers/objects/StringHelper';
import UrlBuilder from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlBuilder';
import UrlHelper from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlHelper';
import type { NextSeoProps } from 'next-seo';
import { useContext } from 'react';
import { canUseDOM } from 'exenv';
import ConversationStyleBehaviorHelper from '../../../helpers/boards/ConversationStyleBehaviorHelper';
import { UrlObject, useContextObjectRefFromUrl } from '../../context/useContextObjectFromUrl';
import { SEO_DESCRIPTION_MAX_LENGTH } from '../../useNodePageSeoProps';
import useTranslation from '../../useTranslation';

/**
 * Handles all the necessary seo data on message pages
 *
 * @param pageComponent Current page component
 *
 * @returns Next seo props
 */
export function useMessagePageSeo(pageComponent: EndUserComponent): NextSeoProps {
  const { formatMessage } = useTranslation(pageComponent);
  const { community, contextMessage } = useContext(AppContext);
  const { baseUrl } = useContext(TenantContext);
  const { id } = useContextObjectRefFromUrl(UrlObject.MESSAGE);
  const { router } = useEndUserRoutes();

  if (!contextMessage) {
    return null;
  }

  const {
    rawBody,
    board: { conversationStyle },
    currentRevision: { lastEditTime },
    images: { edges },
    uid
  } = contextMessage;

  const { coverImage, coverImageProperties } = contextMessage as BlogTopicMessage;

  const { seoDescription, canonicalUrl, seoTitle } = contextMessage as TopicMessage;

  const { rawTeaser } = contextMessage as Article;

  const {
    seoProperties: { appendTopicUidInHead }
  } = community;

  const title =
    seoTitle ||
    formatMessage('title', {
      communityTitle: community.title,
      contextMessageSubject: contextMessage.subject
    });

  const seoDescriptionDerived =
    seoDescription ||
    (rawTeaser && stripHtmlFromString(rawTeaser)) ||
    (rawBody && stripHtmlFromString(rawBody));
  const metaDescription = truncateString(seoDescriptionDerived, SEO_DESCRIPTION_MAX_LENGTH);
  const beforeCursorQueryParam = router.getUnwrappedQueryParam(EndUserQueryParams.BEFORE);
  const messageSortQueryParam = router.getUnwrappedQueryParam(
    EndUserQueryParams.MESSAGE_LIST_MENU_QUERY_PARAMETER
  );
  const repliesSortQueryParam = router.getUnwrappedQueryParam(
    EndUserQueryParams.SORT_MENU_QUERY_PARAMETER
  );
  /**
   * For those pages which have sort or before cursor in route, the robots meta should have noIndex as per requirement.
   * Only if after cursor is present then we should index.
   */
  const nextSeoProps: NextSeoProps = {
    title: appendTopicUidInHead ? `${title} - ${uid}` : title,
    description: appendTopicUidInHead ? `${metaDescription} - ${uid}` : metaDescription,
    noindex: !!repliesSortQueryParam || !!messageSortQueryParam || !!beforeCursorQueryParam
  };

  if (canonicalUrl) {
    nextSeoProps.canonical = canonicalUrl;
  } else if (id !== null) {
    const { messagePage } = ConversationStyleBehaviorHelper.getInstance(contextMessage.board);
    const routePath = router.getRelativeUrlForRoute<MessagePagesAndParams>(messagePage, {
      boardId: contextMessage.board.displayId,
      messageSubject: UrlHelper.determineSlugForMessagePath(contextMessage),
      messageId: String(contextMessage.uid)
    });
    nextSeoProps.canonical = UrlBuilder.fromUrl(baseUrl).addPath(routePath).build();
  }

  // For Blog Message Page
  if (coverImage && coverImageProperties) {
    const { url, width, height } = coverImage;
    const { altText } = coverImageProperties;
    nextSeoProps.openGraph = {
      ...nextSeoProps.openGraph,
      images: [
        {
          url,
          width,
          height,
          alt: altText || ''
        }
      ]
    };
  }

  // For Forum and Tkb Message Pages
  if (edges.length > 0 && !coverImage) {
    const [firstImage] = edges;
    const {
      node: { url, width, height, altText }
    } = firstImage;
    nextSeoProps.openGraph = {
      ...nextSeoProps.openGraph,
      images: [
        {
          url,
          width,
          height,
          alt: altText || ''
        }
      ]
    };
  }

  if (canUseDOM) {
    const parser = new global.DOMParser();
    const parsedContent = parser.parseFromString(rawBody, 'text/html');
    if (parsedContent?.querySelectorAll) {
      const videoList = parsedContent.querySelectorAll('li-video');
      // If videos are present in the article
      if (videoList.length > 0) {
        const openGraphVideos = [];
        videoList.forEach(currentVideo => {
          const width = currentVideo.getAttribute('width');
          const height = currentVideo.getAttribute('height');
          const url: string = currentVideo.getAttribute('vid');
          const altText = currentVideo.getAttribute('title');
          const isValidUrl = UrlHelper.isValidHTTPUrl(url);
          if (isValidUrl) {
            openGraphVideos.push({
              url,
              width: Number.parseInt(width),
              height: Number.parseInt(height),
              alt: altText || ''
            });
          }
        });
        if (openGraphVideos.length > 0) {
          const [firstVideo] = openGraphVideos;
          nextSeoProps.openGraph = {
            ...nextSeoProps.openGraph,
            videos: [firstVideo]
          };
        }
      }
    }
  }

  // Modified time to be added only for blog and tkb article pages
  if ([ConversationStyle.Blog, ConversationStyle.Tkb].includes(conversationStyle)) {
    nextSeoProps.openGraph = {
      ...nextSeoProps.openGraph,
      article: {
        modifiedTime: lastEditTime
      }
    };
  }

  return nextSeoProps;
}
