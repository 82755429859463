import { IconColor, IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import ThemedContainer from '@aurora/shared-client/components/common/ThemedContainer/ThemedContainer';
import type { AppContextInterface } from '@aurora/shared-client/components/context/AppContext/AppContext';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useNodePolicies from '@aurora/shared-client/components/nodes/useNodePolicies';
import { canViewSpamDashBoard } from '@aurora/shared-client/helpers/nodes/NodePolicyHelper';
import { isNodeForum } from '@aurora/shared-client/helpers/nodes/NodeUtils';
import Icons from '@aurora/shared-client/icons';
import type { ManageContentPageAndParams } from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import {
  ModerationAction,
  ModerationStatus
} from '@aurora/shared-generated/types/graphql-schema-types';
import {
  EndUserComponent,
  EndUserPages,
  EndUserQueryParams
} from '@aurora/shared-types/pages/enums';
import React, { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import EditContext from '../../context/EditContext/EditContext';
import useTranslation from '../../useTranslation';
import { MessageListTabItem } from '../MessageListTabs/MessageListTabs';
import localStyles from './MessageBanner.module.pcss';

interface Props {
  /**
   * The class name(s) applied to the component element.
   */
  className?: string;
}

/**
 * Message Banner.
 * @author Aditi Agarwal
 */
const MessageBanner: React.FC<React.PropsWithChildren<Props>> = ({ className }) => {
  const cx = useClassNameMapper(localStyles);
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_BANNER_WIDGET
  );
  const { quilt } = useContext(EditContext);
  const { contextMessage } = useContext<AppContextInterface>(AppContext);
  const {
    publicConfig: { quiltsV2Enabled }
  } = useContext(TenantContext);
  const { router, Link } = useEndUserRoutes();
  const { data: nodePoliciesData, loading: nodePoliciesLoading } = useNodePolicies(module, {
    useCanViewSpamDashBoard: true
  });

  const { rejectReason, status } = contextMessage?.moderationData || {};
  const isRejectReasonSpam = rejectReason === ModerationAction.Spam;

  const willRender: boolean =
    (isRejectReasonSpam || rejectReason === 'ABUSE' || rejectReason === 'OTHER') &&
    status === ModerationStatus.Rejected;

  if (textLoading || nodePoliciesLoading || quilt) {
    return null;
  }

  const queryParams = {
    [EndUserQueryParams.TAB]: router.getUnwrappedQueryParam(
      EndUserQueryParams.TAB,
      isRejectReasonSpam ? MessageListTabItem.SPAM : MessageListTabItem.ABUSE
    )
  };

  /**
   * Renders the link to spam dashboard.
   */
  function renderSpamDashboardLink(): React.ReactElement {
    return (
      canViewSpamDashBoard(nodePoliciesData?.coreNode) && (
        <div className={cx('lia-message-spam')}>
          <span className={cx('lia-message-spam-link')}>
            <Link<ManageContentPageAndParams>
              route={EndUserPages.ManageContentPage}
              query={queryParams}
              legacyBehavior={true}
            >
              {formatMessage('manageSpam')}
            </Link>
          </span>
        </div>
      )
    );
  }

  /**
   * Renders the banner for message when marked as spam.
   */
  function renderBannerForMessage(): React.ReactElement {
    return (
      <ThemedContainer>
        <div
          className={cx(
            'lia-message-status-container',
            // TODO: remove this check as part of the quilts v2 config cleanup
            `lia-message-status-container${quiltsV2Enabled ? '-v2' : ''}-${
              isNodeForum(contextMessage?.board) ? 'forum' : 'message'
            }`,
            className
          )}
          data-testid="MessageBanner"
        >
          <span className={cx('lia-g-mr-10')}>
            <Icon icon={Icons.FlagIcon} color={IconColor.WARNING} size={IconSize.PX_16} />
          </span>
          <div>
            {formatMessage(
              isRejectReasonSpam
                ? 'messageMarkedAsSpam'
                : rejectReason === 'ABUSE'
                ? 'messageMarkedAsAbuse'
                : 'messageMarkedAsOther'
            )}
            {isRejectReasonSpam && renderSpamDashboardLink()}
          </div>
        </div>
      </ThemedContainer>
    );
  }

  return willRender && renderBannerForMessage();
};

export default MessageBanner;
