import ThemedContainer from '@aurora/shared-client/components/common/ThemedContainer/ThemedContainer';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import dynamic from 'next/dynamic';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import localStyles from './MessageActionToolbar.module.pcss';

const MessageActionToolbarMain = dynamic(() => import('./MessageActionToolbarMain'), {
  ssr: false
});
/**
 * Displays a toolbar to take actions as per the message state.
 *
 * @author Ritu Somani
 */
const MessageActionToolbar: React.FC<React.PropsWithChildren<unknown>> = () => {
  const cx = useClassNameMapper(localStyles);
  const { isAnonymous } = useRegistrationStatus();
  if (isAnonymous) {
    return null;
  } else {
    return (
      <ThemedContainer className={cx('lia-message-action-themed-container')}>
        <MessageActionToolbarMain />
      </ThemedContainer>
    );
  }
};
export default MessageActionToolbar;
