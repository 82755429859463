import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useSeoProperties from '@aurora/shared-client/components/seo/useSeoProperties';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import { EndUserPages } from '@aurora/shared-types/pages/enums';
import { stripHtmlFromString } from '@aurora/shared-utils/helpers/objects/StringHelper';
import type { ArticleJsonLdProps } from 'next-seo';
import { ArticleJsonLd } from 'next-seo';
import React, { useContext } from 'react';
import {
  UrlObject,
  useContextObjectRefFromUrl
} from '../../../components/context/useContextObjectFromUrl';
import MessagePage from '../../../components/page/MessagePage/MessagePage';
import { buildMessageUrl } from '../../../helpers/seo/TkbSchemaHelper';
import type { MessageViewFragment } from '../../../types/graphql-types';
import useRedirectToArchivalSuggestedUrl from '../../../components/useRedirectToArchivalSuggestedUrl';

/**
 * Blog Message Page
 *
 * @author Sravan Reddy
 */
const BlogMessagePage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { contextMessage } = useContext(AppContext);
  const { baseUrl } = useContext(TenantContext);
  const { router } = useEndUserRoutes();
  const { getCaseSensitivePath, customOGSiteName } = useSeoProperties();
  const { id: replyId } = useContextObjectRefFromUrl(UrlObject.REPLY);
  const isPermalinkPage = replyId !== null;
  const twitterCardType = 'summary_large_image';

  useRedirectToArchivalSuggestedUrl();

  /**
   * Function to render the schema for the blog post page.
   */
  function renderBlogArticleSchema(): React.ReactElement {
    const {
      author: { login, avatar },
      subject,
      body,
      postTime,
      currentRevision: { lastEditTime },
      images: { edges: imageEdges }
    } = contextMessage;
    const articleJsonLd: ArticleJsonLdProps = {
      type: 'BlogPosting',
      url: buildMessageUrl(
        contextMessage as MessageViewFragment,
        router,
        baseUrl,
        getCaseSensitivePath
      ),
      title: stripHtmlFromString(subject),
      description: stripHtmlFromString(body),
      images: imageEdges.length > 0 ? [imageEdges[0].node.url] : [],
      datePublished: new Date(postTime).toLocaleString(),
      dateModified: new Date(lastEditTime).toLocaleString(),
      publisherName: customOGSiteName || '',
      authorName: !avatar
        ? login
        : {
            name: login,
            url: avatar.url
          }
    };
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <ArticleJsonLd {...articleJsonLd} />;
  }

  if (!contextMessage) {
    return null;
  }
  return (
    <>
      <MessagePage pageId={EndUserPages.BlogMessagePage} twitterCardType={twitterCardType} />
      {!isPermalinkPage && renderBlogArticleSchema()}
    </>
  );
};
export default BlogMessagePage;
