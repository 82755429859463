import type { MessagePagesAndParams } from '@aurora/shared-client/routes/endUserRoutes';
import UrlHelper from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlHelper';
import type { EndUserRouter } from '@aurora/shared-client/routes/useEndUserRoutes';
import UrlBuilder from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlBuilder';
import ConversationStyleBehaviorHelper from '../boards/ConversationStyleBehaviorHelper';
import type { MessageViewFragment } from '../../types/graphql-types';

/**
 *
 * @param node Message view fragment
 * @param router Custom Router
 * @param baseUrl Base Url of the app
 * @param getCaseSensitivePath To get the case sensitive params based on seo property
 *
 * @returns Url of the article
 */
export function buildMessageUrl(
  node: MessageViewFragment,
  router: EndUserRouter,
  baseUrl: string,
  getCaseSensitivePath: (value: string) => string
): string {
  const {
    conversation: { topic },
    board
  } = node;

  const { messagePage } = ConversationStyleBehaviorHelper.getInstance(board);
  const relativeUrlForRoute = router.getRelativeUrlForRoute<MessagePagesAndParams>(messagePage, {
    boardId: getCaseSensitivePath(board.displayId),
    messageSubject: UrlHelper.determineSlugForMessagePath(topic),
    messageId: topic.uid.toString()
  });

  return UrlBuilder.fromUrl(baseUrl).addPath(relativeUrlForRoute).build();
}
