import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import { RedirectStatusCode } from '@aurora/shared-client/components/useIsomorphicRedirect';
import useIsomorphicRedirectByUrl from '@aurora/shared-client/components/useIsomorphicRedirectByUrl';
import { VisibilityScope } from '@aurora/shared-generated/types/graphql-schema-types';
import { getLog } from '@aurora/shared-utils/log';
import { useContext } from 'react';
import type { TopicMessage } from '@aurora/shared-generated/types/graphql-schema-types';

const log = getLog(module);
/**
 *
 * If message is archived, Redirects to message's suggested url if present.
 *
 * @author Muttu K Sulikeri
 */
export default function useRedirectToArchivalSuggestedUrl(): void {
  const { contextMessage } = useContext(AppContext);
  const isArchivedMessage: boolean = contextMessage?.visibilityScope === VisibilityScope.Archived;
  let suggestedUrl: string;

  if (contextMessage.depth === 0) {
    const message = contextMessage as TopicMessage;
    suggestedUrl = isArchivedMessage ? message?.archivalData?.suggestedUrl : null;
    if (suggestedUrl) {
      log.debug("redirecting to archived message's suggested url:", suggestedUrl);
    }
  }

  useIsomorphicRedirectByUrl(isArchivedMessage, suggestedUrl, RedirectStatusCode.MOVED_PERMANENTLY);
}
